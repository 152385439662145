<template>
  <div>
    <b-modal 
      v-model="open" 
      no-close-on-backdrop
      hide-footer
      no-fade
      size="huge"
      class="customer-add"
      id="address-list-modal"
    >
      <template
        slot="modal-header"
      >
        <b-button
          variant="outline-light"
          @click="closeModal"
        >
          {{ $t('customerpopup.cancel') }}
        </b-button>
        <h5>
          {{ customerName }}
        </h5>
        <b-button
          variant="primary"
          @click="initNewAddress()"
        >
          {{ $t('customerpopup.add-address') }}
        </b-button>
      </template>

      <div class="address-container">
        <div v-if="Array.isArray(addressList) && addressList.length > 0">
          <b-card
            v-for="(address,index) in customerAddressList"
            :key="index"
            class="w-100 mb-2"
          >
            <b-card-text class="d-flex justify-content-start w-100">
              <div class="align-items-center">
                <span>{{ address.address_1 ? address.address_1 + ', ' : '' }}</span>
                <span>{{ address.address_2 ? address.address_2+ ', ': '' }}</span>
                <span>{{ address.country ? getCountryValue(address.country)+ ', ': '' }}</span>
                <span>{{ address.state ? address.state+', ': '' }}</span>
                <span>{{ address.city ? address.city+', ': '' }}</span>
                <span>{{ address.postal_code ? address.postal_code+'. ': '' }}</span>
              </div>
              <div class="ml-auto d-flex">
                <b-btn
                  class="mr-2"
                  @click="openUpdateModal(address.id||address.local_id)"
                >
                  Update
                </b-btn>
                <b-btn @click="deleteCustomerAddress(address.id||address.local_id)">
                  Delete
                </b-btn>
              </div>
              <br>
            </b-card-text>
          </b-card>
        </div>
        <div
          v-else
          class="w-100 h-100 d-flex justify-content-center align-items-center"
        >
          <h4>{{ $t('customerpopup.no-address-found') }}</h4>
        </div>
      </div>
    </b-modal>
    <UpdateAddressModal
      :selected-address="customerSelectedAddress"
      :show-update-modal="showUpdateModal"
      :address-action="addressAction"
      @updateAddress="updateAddress"
      @addNewAddress="addNewAddress"
    />
  </div>
</template>

<script>
import {mapGetters ,mapActions} from 'vuex'
import customerAddressHelper from "@/helpers/customer/customer"
export default{

    components:{
      UpdateAddressModal : ()=> import('./UpdateAddressModal.vue')
    },

    props:{
      customer:{
        type:Object,
        default:()=>({})
      },
      open:{
        type:Boolean,
        default:false
      }
    },

    data : ()=>({
        showUpdateModal: false,
        defaultAddress:  {},
        addressList:[],
        selectedAddress : null,
        newAddress:{
          country:null,
          state:null,
          first_name:null,
          last_name:null,
          company:null,
          city:null,
          postal_code:null,
          phone:null,
          address_1:null,
          address_2:null
        },
        addressAction:'update'
    }),

    computed:{
      ...mapGetters({
          countryOptions:'customers/countries',
          statesOptions:'customers/usaState'
        }),
        customerName(){
          if(this.customer && Object.keys(this.customer).length > 0){
            return `${this.customer.first_name} ${this.customer.last_name}`
          }
          return ''
        },
        customerSelectedAddress(){
          if( this.customer && Object.keys(this.customer).length > 0 && this.selectedAddress){
          return this.customer.addresses.find(address=>address.id === this.selectedAddress || address.local_id === this.selectedAddress)
        }
          return this.newAddress
        },
        customerAddressList(){
          return this.addressList
        },
    },

    watch:{
      customer:{
        handler(val){
          if(val){
            this.selectedAddress = this.customer.default_address?.id
            this.addressList  = this.customer?.addresses
          }
        },
        immediate:true,
      }
    },

    methods:{
      ...mapActions({
        uploadCustomers:'customers/uploadUpdated',
        deleteAddress:"customers/deleteAddress"
      }),
      getCountryValue(country=null){
        if(country){
          return customerAddressHelper.deSerializeCountryValue(country)
        }
      },
      closeModal(){
        this.$emit('closeModal' )
      },
      openUpdateModal(addressId){
          this.selectedAddress = addressId
          this.addressAction = 'update'
          this.$bvModal.show('update-add-address-modal')
      },
      initNewAddress(){
           this.selectedAddress = null
           this.addressAction = 'add'
           this.$bvModal.show('update-add-address-modal')
      },
      updateAddress(addressDetails)
      {
        if(addressDetails.default)
          this.updateDefault()
        this.addressList = [...this.addressList.map(address=>address.id === addressDetails.id || address.local_id === addressDetails.id ? {...addressDetails} : address)]
        // const addressIndex = this.addressList.findIndex(address=>address.id === addressDetails.id || address.local_id === addressDetails.local_id)
        // this.addresList[addressIndex] = {...addressDetails}
        this.customer.addresses = [...this.addressList]
        this.$store.commit('customers/save', this.customer)
        this.uploadCustomers()
        this.$bvModal.hide('update-add-address-modal')
      },
      addNewAddress(addressDetails){
        if(addressDetails.default)
          this.updateDefault()
        addressDetails.local_id = (new Date).toISOString()
        this.addressList.push(addressDetails)
        this.customer.addresses = [...this.addressList]
        this.$store.commit('customers/save', this.customer)
        this.uploadCustomers()
        this.$bvModal.hide('update-add-address-modal')
      },
      updateDefault(){
        this.addressList.forEach(address => address.default = false);
      },
      async deleteCustomerAddress(addressId){
        let addressIndex = this.addressList.findIndex(address=>address.id === addressId)
        if(addressIndex > -1)
        {
        // const isDeleted = this.deleteAddress(addressId)
        // if(isDeleted)
          this.deleteAddress(addressId)
          this.addressList.splice(addressIndex,1)
        }
        else
        {
          addressIndex = this.addressList.findIndex(address=>address.local_id === addressId)
          this.addressList.splice(addressIndex,1)
        }

      }
  
    }


}


</script>
<style>

#address-list-modal {
  overflow: hidden;
}
#address-list-modal {
  height: 93vh;
}
#address-list-modal  .address-container {
  overflow-y: auto;
  height: 390px;
}

.modal .modal-huge{
  width:900px;
  max-width: 900px;
}

</style>